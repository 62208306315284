<template>
  <div class="content-wrap supplier_settl_view">
    <div class="content-select">
      <el-form
        ref="selectFrom"
        :model="queryParam"
        :inline="true"
        label-width="100px"
        label-position="right"
        style="padding: 5px 20px"
      >
        <el-form-item label="结算单号">
          <el-input
            v-model.trim="queryParam.sheetCode"
            placeholder="请输入结算单号"
            maxlength="32"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="业务单号">
          <el-input
            v-model.trim="queryParam.businessCode"
            placeholder="请输入业务单号"
            maxlength="32"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="供方编号">
          <el-input
            v-model.trim="queryParam.sellerMainCode"
            placeholder="请输入供方编号"
            maxlength="32"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="供方主体" v-if="cooRole == 'DS'">
          <query-seller-select :model="queryParam"></query-seller-select>
        </el-form-item>
        <el-form-item
          label="需方主体"
          v-if="cooRole == 'DS'"
          v-show="queryExpended"
        >
          <organization-select
            :model="queryParam"
            orgIdProp="buyerOrgId"
            :clearable="true"
            :filterable="true"
            placeholder="请选择需方主体"
            :is-add-all="false"
          ></organization-select>
        </el-form-item>
        <el-form-item label="结算阶段" v-show="queryExpended">
          <el-select
            v-model="queryParam.sheetStage"
            multiple
            placeholder="请选择结算阶段"
            collapse-tags
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in dictionary.tradeSheetStage"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="稽核状态" v-show="queryExpended">
          <el-select
            v-model="queryParam.auditStatus"
            multiple
            placeholder="请选择稽核状态"
            collapse-tags
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in dictionary.tradeAuditStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票状态" v-show="queryExpended">
          <el-select
            v-model="queryParam.billStatus"
            multiple
            placeholder="请选择开票状态"
            collapse-tags
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in dictionary.tradeBillStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交票状态" v-show="queryExpended">
          <el-select
            v-model="queryParam.receiveStatus"
            multiple
            placeholder="请选择交票状态"
            collapse-tags
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in dictionary.tradeReceiveStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算时间" v-show="queryExpended">
          <el-date-picker
            v-model="queryParam.tradeTime"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="tradeTimeOptions"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="过期时间" v-show="queryExpended">
          <el-date-picker
            class="date-picker"
            v-model="queryParam.expiredTime"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="合规状态" v-show="queryExpended">
          <el-select
            v-model="queryParam.complianceStatus"
            multiple
            placeholder="请选择合规状态"
            collapse-tags
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in dictionary.tradeComplianceStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="回写状态" v-show="queryExpended">
          <el-select
            v-model="queryParam.writeBackStatus"
            multiple
            placeholder="请选择回写状态"
            collapse-tags
            clearable
            filterable
            size="small"
          >
            <el-option
              v-for="item in dictionary.tradeWriteBackStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="margin-left: 32px">
          <select-button
            show-status
            @list-close="handleListClose"
            @list-show="handleListShow"
            @select="handleQuery"
            @reset="handleReset"
          ></select-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-main">
      <div class="content-operate">
        <el-button
          type="primary"
          :disabled="checkBtnDisabled"
          @click="handleCheckTrade"
          v-hasPermi="['coo:trade:check']"
          >合规检查</el-button
        >
        <el-button
          type="primary"
          v-if="cooRole == 'SS'"
          :disabled="generateBtnDisabled"
          @click="handleGenerateInvoice"
          v-hasPermi="['coo:trade:invoice']"
          >生成发票</el-button
        >
        <el-button
          type="warning"
          v-if="cooRole == 'DS'"
          :disabled="editTicketCountBtnDisabled"
          @click="handleEditTicketCount"
          v-hasPermi="['coo:trade:edit-ticket-count']"
          >修改票数</el-button
        >
        <el-dropdown
          @command="handleReceiveInvoice"
          :disabled="receiveBtnDisabled"
        >
          <el-button type="default" v-hasPermi="['coo:trade-receiving:upload']"
            >上传发票<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="localup">本地上传</el-dropdown-item>
            <el-dropdown-item command="scanner">扫描上传</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          type="warning"
          :disabled="backBtnDisabled"
          @click="handleSettlementBack"
          v-hasPermi="['coo:trade:back']"
          >回退订单</el-button
        >
        <el-button
          type="warning"
          :disabled="revokeBtnDisabled"
          @click="handleSettlementRevoke"
          v-hasPermi="['coo:trade:revoke']"
          >作废订单</el-button
        >
        <el-button
          type="warning"
          :disabled="deleteBtnDisabled"
          @click="handleSettlementDelete"
          v-if="cooRole == 'DS'"
          v-hasPermi="['coo:trade:delete']"
          >删除订单</el-button
        >
        <el-button
          type="success"
          :disabled="returnBtnDisabled"
          @click="handleSettlementReturn"
          v-hasPermi="['coo:trade:return']"
          >结算回写
        </el-button>
        <el-button type="default" @click="SettlementExportBatch"
          >导出订单</el-button
        >
      </div>
      <div class="content-table">
        <el-table
          stripe
          border
          :data="tableData"
          v-loading="dataLoading"
          show-summary
          :summary-method="getTableSummary"
          @selection-change="handleSelectionChange"
          :header-cell-style="handleHeaderCellStyle"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            fixed="left"
            width="55"
            align="center"
          />

          <el-table-column type="index" fixed="left" label="序号" width="60" />
          <el-table-column
            style="cursor: pointer"
            prop="sheetCode"
            fixed="left"
            label="结算单号"
            min-width="200"
          >
            <template slot-scope="scope">
              <el-link @click="handleRedirectDetail(scope.row)"
                >{{ scope.row.sheetCode }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="tradeTime"
            label="单据时间"
            width="180"
            :formatter="fmtDateTime"
          />
          <el-table-column
            prop="sellerMainName"
            label="供方名称"
            min-width="220"
            show-overflow-tooltip
          />
          <el-table-column
            prop="sellerMainCode"
            label="供方编码"
            min-width="160"
            show-overflow-tooltip
          />
          <el-table-column
            prop="buyerMainName"
            label="需方名称"
            min-width="220"
            show-overflow-tooltip
          />
          <el-table-column
            prop="buyerCode"
            label="需方编码"
            min-width="220"
            show-overflow-tooltip
          />
          <el-table-column
            prop="sheetSumAmount"
            label="结算金额"
            width="140"
            header-align="right"
            align="right"
            :formatter="fmtInvoiceAmount"
          />
          <el-table-column
            prop="sheetStatus"
            label="订单状态"
            width="100"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{
                getDictLabel(dictionary.tradeSheetStatus, scope.row.sheetStatus)
              }}
            </template>
          </el-table-column>
          <el-table-column prop="billStatus" label="开票状态" width="160">
            <template slot-scope="scope">
              <el-link @click="handleRedirectInvoicing(scope.row)">
                {{
                  getDictLabel(
                    dictionary.tradeBillStatus,
                    scope.row.billStatus
                  )
                }}（{{ scope.row.billedCount }}/{{ scope.row.ticketCount }}）
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop="receiveStatus" label="交票状态" width="160">
            <template slot-scope="scope">
              <el-link @click="handleRedirectReceiving(scope.row)"
                >{{
                  getDictLabel(
                    dictionary.tradeReceiveStatus,
                    scope.row.receiveStatus
                  )
                }}（{{ scope.row.receivedCount }}/{{ scope.row.ticketCount }}）
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop="sheetStage" label="结算阶段" width="100">
            <template slot-scope="scope">
              <span>
                {{
                  getDictLabel(dictionary.tradeSheetStage, scope.row.sheetStage)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="auditStatus" label="稽核状态" width="100">
            <template slot-scope="scope">
              <span>
                {{
                  getDictLabel(
                    dictionary.tradeAuditStatus,
                    scope.row.auditStatus
                  )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="complianceStatus" label="合规状态" width="100">
            <template slot-scope="scope">
              <span>
                {{
                  getDictLabel(
                    dictionary.tradeComplianceStatus,
                    scope.row.complianceStatus
                  )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="writeBackStatus" label="回写状态" width="100">
            <template slot-scope="scope">
              <span>
                {{
                  getDictLabel(
                    dictionary.tradeWriteBackStatus,
                    scope.row.writeBackStatus
                  )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="expiredType" label="过期类型" width="100">
            <template slot-scope="scope">
              <span>
                {{
                  getDictLabel(
                    dictionary.tradeExpiredType,
                    scope.row.expiredType
                  )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="expiredTime"
            label="过期时间"
            width="180"
            :formatter="fmtDateTime"
          />
          <el-table-column
            prop="created"
            label="创建时间"
            width="180"
            :formatter="fmtDateTime"
          />
          <el-table-column
            prop="operate"
            label="操作"
            fixed="right"
            width="200"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleReceiveInvoice($event, scope.row)">
                <el-button
                  type="text"
                  :disabled="handleReceiveDisabled(scope.row)"
                  >上传发票<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="localup"
                    >本地上传</el-dropdown-item
                  >
                  <el-dropdown-item command="scanner"
                    >扫描上传</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="text" @click="handleRedirectMatching(scope.row)"
                >稽核详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParam.pageNo"
          :page-sizes="pageSizes"
          :page-size="queryParam.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>

    <delete-trade ref="deleteTradeRef" @success="handleQuery"></delete-trade>

    <back-trade
      ref="backTradeRef"
      @success="handleQuery"
      :dictionary="dictionary"
    ></back-trade>

    <revoke-trade ref="revokeTradeRef" @success="handleQuery"></revoke-trade>

    <check-trade ref="checkTradeRef" @success="handleQuery"></check-trade>

    <return-trade ref="returnTradeRef" @success="handleQuery"></return-trade>

    <update-trade
      ref="updateTradeRef"
      @refreshPage="handleQuery"
    ></update-trade>

    <edit-ticket-count
      ref="editTicketCountRef"
      @success="handleQuery"
    ></edit-ticket-count>

    <generate-invoice
      ref="generateInvoiceRef"
      @success="handleQuery"
    ></generate-invoice>
  </div>
</template>

<script>
import CheckTrade from "@/views/cooperate/settlement/components/checkTrade.vue";
import UpdateTrade from "@/views/cooperate/settlement/components/updateTrade.vue";
import DeleteTrade from "@/views/cooperate/settlement/components/deleteTrade.vue";
import BackTrade from "@/views/cooperate/settlement/components/backTrade.vue";
import RevokeTrade from "@/views/cooperate/settlement/components/revokeTrade.vue";
import ReturnTrade from "@/views/cooperate/settlement/components/returnTrade.vue";
import EditTicketCount from "@/views/cooperate/settlement/components/editTicketCount.vue";
import QuerySellerSelect from "@/views/cooperate/settlement/widgets/QuerySellerSelect.vue";
import GenerateInvoice from "@/views/cooperate/settlement/components/invoicing/generateInvoice.vue";

import { fmtCurrency } from "@/assets/js/format-util";
import {
  tableStyle,
  pagingMethods,
  dateOption,
  changeMethods,
} from "@/util/mixins";
import { reqPagingSettlement, settlementExport } from "@/service/coo/trade.js";
import { getList } from "@/service/sellerManage";
import OrganizationSelect from "@/components/widgets/OrganizationSelect.vue";

export default {
  name: "CooTrade",
  components: {
    BackTrade,
    CheckTrade,
    UpdateTrade,
    DeleteTrade,
    RevokeTrade,
    ReturnTrade,
    GenerateInvoice,
    EditTicketCount,
    QuerySellerSelect,
    OrganizationSelect,
  },
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  mixins: [dateOption, tableStyle, pagingMethods, changeMethods],
  data() {
    return {
      // 数据字典
      dictionary: {
        tradeSheetStage: [], //结算阶段
        tradeBillStatus: [], //开票状态
        tradeExpiredType: [], //过期类型
        tradeAuditStatus: [], //稽核状态
        tradeReceiveStatus: [], //交票状态
        tradeWriteBackStatus: [], //回写状态
        tradeComplianceStatus: [], //合规状态
        tradeSheetStatus: [], //订单状态
      },

      backBtnDisabled: true,
      checkBtnDisabled: true,
      revokeBtnDisabled: true,
      deleteBtnDisabled: true,
      returnBtnDisabled: true,
      receiveBtnDisabled: true,
      generateBtnDisabled: true,
      editTicketCountBtnDisabled: true,

      // 需方列表
      buyerList: [],
      buyerListLoading: false,
      // 供方列表
      sellerList: [],
      sellerListLoading: false,

      // 筛选条件
      queryParam: {
        sheetCode: undefined,
        businessCode: undefined,
        sellerMainCode: undefined,
        buyerOrgId: [],
        sellerOrgId: [],
        sheetStage: [],
        auditStatus: [],
        billStatus: [],
        receiveStatus: [],
        tradeTime: [],
        expiredTime: [],
        complianceStatus: [],
        writeBackStatus: [],
        pageNo: 1,
        pageSize: 15,
      },
      // 展开条件
      queryExpended: false,
      // 结算时间
      tradeTimeOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now() + 0 * 24 * 3600 * 1000;
        },
      },
      // 表格数据
      dataTotal: 0,
      tableData: [],
      dataLoading: false,

      // 选中数据
      ids: [],
      trades: [],
      single: false,
      multiple: false,
    };
  },
  async created() {
    // console.log("SettlementTradeList:", this.cooRole);
    this.$watch(
      () => this.$route.path,
      async () => {
        if (this.cooRole == "DS") {
          this.queryBuyerList();
        }
        this.querySellerList();
        await this.initDict();
        await this.handleQuery();
      }
    );

    if (this.cooRole == "DS") {
      this.queryBuyerList();
    }
    this.querySellerList();
    await this.initDict();
    await this.handleQuery();
  },
  methods: {
    // 初始化字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: [
          "trade_sheet_stage",
          "trade_audit_status",
          "trade_bill_status",
          "trade_receive_status",
          "settlement-expire-type",
          "trade_write_back_status",
          "data_compliance_status",
          "trade_sheet_status",
        ],
      });
      this.dictionary.tradeSheetStage = data["trade_sheet_stage"];
      this.dictionary.tradeBillStatus = data["trade_bill_status"];
      this.dictionary.tradeExpiredType = data["settlement-expire-type"];
      this.dictionary.tradeAuditStatus = data["trade_audit_status"];
      this.dictionary.tradeReceiveStatus = data["trade_receive_status"];
      this.dictionary.tradeWriteBackStatus = data["trade_write_back_status"];
      this.dictionary.tradeComplianceStatus = data["data_compliance_status"];
      this.dictionary.tradeSheetStatus = data["trade_sheet_status"];
    },
    // 查询需方列表
    async queryBuyerList() {
      this.buyerListLoading = true;
      this.buyerList = this.$store.state.user.organizations;
      this.buyerListLoading = false;
    },
    // 查询供方列表
    async querySellerList() {
      this.sellerListLoading = true;
      if (this.cooRole == "DS") {
        const param = {
          filter: {},
          paging: {
            current: 1,
            size: 1000,
          },
        };
        const { success, data } = await getList(param);
        if (success) {
          let list = [];
          const records = data.records;
          if (records > 0) {
            list = records.map((item) => {
              return { id: item.supplierOrgId, name: item.fullName };
            });
          }
          this.sellerList = list;
        }
      }
      if (this.cooRole == "SS") {
        this.sellerList = this.$store.state.user.organizations;
      }
      this.sellerListLoading = false;
    },
    // 查询订单列表
    async handleQuery() {
      this.dataLoading = true;
      const { success, data } = await reqPagingSettlement(this.queryParam);
      if (success) {
        data.list.forEach((item) => (item.check = false));
        this.tableData = data.list;
        this.dataTotal = data.total;
      }
      this.dataLoading = false;
    },

    /** 批量导出 **/
    async SettlementExportBatch() {
      if (this.trades.length == 0) {
        this.toast("请勾选需要导出的信息", "warning");
        return;
      }
      if (this.trades.length > 100) {
        this.toast("导出失败，一次最多只能导出100条", "warning");
        return;
      }
      let ids = this.trades.map((i) => i.id);
      this.dataLoading = true;
      const rsp = await settlementExport({ sheetIds: ids });
      this.downloadFile(rsp);
      this.dataLoading = false;
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = value;
      this.handleQuery();
    },
    // 订单列表翻页
    handleCurrentChange(value) {
      this.queryParam.pageNo = value;
      this.handleQuery();
    },
    // 转到订单详情
    handleRedirectDetail(settlement) {
      this.$router.replace({
        path: `/coo/${this.cooRole == "DS" ? "buy" : "sal"}/trade/detail`,
        query: { id: settlement.id },
      });
    },
    // 转到开票详情
    handleRedirectInvoicing(settlement) {
      this.$router.replace({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/invoicing/detail`,
        query: { id: settlement.id },
      });
    },
    // 转到稽核详情
    handleRedirectMatching(settlement) {
      this.$router.replace({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/matching/detail`,
        query: { id: settlement.id },
      });
    },
    // 转到交票详情
    handleRedirectReceiving(settlement) {
      this.$router.replace({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/receiving/detail`,
        query: { id: settlement.id },
      });
    },
    // 处理数据选中
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.trades = selection;
      this.single = this.ids.length == 1;
      this.multiple = this.ids.length > 1;

      this.setButtonDisable();
    },
    setButtonDisable() {
      const trade = this.getSelectedSettlement();
      this.checkBtnDisabled =
        this.trades.length == 0 ||
        this.trades.filter((item) => {
          return item.sheetStage != "BI";
        }).length != 0;
      this.deleteBtnDisabled =
        trade == null || !this.single || trade.sheetStage != "CS";
      this.backBtnDisabled =
        trade == null ||
        !this.single ||
        ["CS", "BI"].indexOf(trade.sheetStage) != -1;
      this.revokeBtnDisabled =
        trade == null ||
        !this.single ||
        trade.billStatus != "SW" ||
        trade.sheetStage == "CS";
      this.editTicketCountBtnDisabled =
        trade == null ||
        !this.single ||
        ["CS", "WJ"].indexOf(trade.sheetStage) != -1 ||
        ["DDCL", "CLSB"].indexOf(trade.sheetStatus) == -1;
      this.returnBtnDisabled =
        this.trades.length == 0 ||
        this.trades.filter((item) => {
          return ["CS", "WJ"].indexOf(item.sheetStage) == -1;
        }).length != 0;
      this.generateBtnDisabled =
        this.trades.length == 0 ||
        this.trades.filter((item) => {
          return (
            item.sheetStage != "BI" ||
            ["DDCL", "CLSB", "BFKP"].indexOf(item.sheetStatus) == -1
          );
        }).length != 0;
      this.receiveBtnDisabled =
        trade == null ||
        !this.single ||
        ["CS", "WJ"].indexOf(trade.sheetStage) != -1;
    },
    // 订单合规校验
    handleCheckTrade() {
      this.$refs.checkTradeRef.showDialog(this.trades);
    },
    // 处理生成发票
    handleGenerateInvoice() {
      this.$refs.generateInvoiceRef.showDialog(this.trades);
    },
    // 处理回写订单
    async handleSettlementReturn() {
      this.$refs.returnTradeRef.showDialog(this.trades);
    },
    // 处理回退订单
    async handleSettlementBack() {
      this.$refs.backTradeRef.showDialog(this.getSelectedSettlement());
    },
    // 处理作废订单
    async handleSettlementRevoke() {
      this.$refs.revokeTradeRef.showDialog(this.getSelectedSettlement());
    },
    // 处理删除订单
    async handleSettlementDelete() {
      this.$refs.deleteTradeRef.showDialog(this.getSelectedSettlement());
    },
    getSelectedSettlement() {
      return this.ids.length == 1
        ? this.tableData.filter((item) => item.id == this.ids[0])[0]
        : null;
    },
    // 修改票据张数
    handleEditTicketCount() {
      this.$refs.editTicketCountRef.showDialog(this.getSelectedSettlement());
    },
    // 收票是否禁用
    handleReceiveDisabled(row) {
      // console.log("handleReceiveDisabled：", row);
      return ["CS", "WJ"].indexOf(row.sheetStage) != -1;
    },
    // 处理收票命令
    handleReceiveInvoice(cmd, row) {
      // console.log("settlement:", settlement);
      if (typeof row == "undefined") {
        row = this.getSelectedSettlement();
      }

      let path = `/coo/${
        this.cooRole == "DS" ? "buy" : "sal"
      }/trade/receiving/${cmd}`;

      this.$router.replace({
        path: path,
        query: {
          sceneName: "JS",
          settlementId: row.id,
          sceneRole: this.cooRole,
        },
      });
    },
    // 收起筛选列表
    handleListClose() {
      this.queryExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.queryExpended = true;
    },
    // 重置查询条件
    handleReset() {
      this.queryParam = {
        sheetCode: "",
        businessCode: "",
        buyerOrgId: "",
        sellerOrgId: "",
        sheetStage: "",
        auditStatus: "",
        billStatus: "",
        receiveStatus: "",
        tradeTime: [],
        expiredTime: [],
        pageNo: 1,
        pageSize: 15,
      };
    },
    fmtDateTime(row, column, value) {
      return value == undefined || value == null || value == ""
        ? "--"
        : this.$moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    fmtInvoiceAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
    // 计算订单汇总
    getTableSummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "汇总";
        } else {
          let idx = ["sheetSumAmount"].indexOf(`${column.property}`);
          if (idx == -1) {
            sums[index] = "";
          } else {
            const values = data.map((item) => {
              if (idx == 0) {
                return Number(item.sheetSumAmount);
              }
            });

            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = fmtCurrency(
                Number(sums[index]).toFixed(2),
                2,
                "",
                ",",
                ".",
                " 元"
              );
            } else {
              sums[index] = "";
            }
          }
        }
      });

      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/select.scss";

.select-header {
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      margin-right: 10px;
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }

    input {
      border: none;
    }

    ::v-deep .el-input__inner {
      border: none;
      border-radius: 0;
    }

    ::v-deep .el-input__icon {
      color: #1890ff;
    }

    span {
      color: #666666;
      font-weight: 400;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .select-title {
    height: auto;
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 186px);

  .content-operate {
    display: flex;
    justify-content: flex-end;

    .el-button {
      padding: 0px 10px;
      margin-bottom: 12px;
      margin-left: 10px;
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }

    .el-dropdown {
      padding: 0 10px 0 10px;
    }

    .el-link {
      color: #3d94ff;
      padding-left: 5px;
      padding-bottom: 4px;
    }
  }
}

.pdt-switch {
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  width: 160px;
  height: 28px;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  border-radius: 14px;
  border: 1px dashed #cccccc;
  background-color: #eeeeee;

  i {
    font-style: normal;
    padding: 0 10px;
    font-size: 12px;
    color: #666666;
  }

  .is-switch {
    position: absolute;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    width: 60px;
    border: 1px dashed #cccccc;
    color: #ffffff;
    border-radius: 14px;
    background-color: #1890ff;
  }

  .is-buyer {
    margin-left: 80px;
  }
}

.tips-body {
  text-align: left;
  margin: -18px 24px 12px 20px;

  span {
    width: 256px;
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}

.dialog-delete {
  padding: 24px 24px 32px;
  text-align: left;

  .delete-top {
    padding-bottom: 12px;

    .label-span {
      margin-bottom: 12px;
    }
  }

  .delete-line {
    margin: 0 -24px;
    border-bottom: 1px solid #e9e9e9;
  }

  .delete-bottom {
    padding-top: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding: 0 24px 24px;
}
.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}
// .el-input__inner {
//   width: 220px;
// }
// .el-input__suffix {
//   right: -14px;
// }
.supplier_settl_view {
  .el-date-editor .el-range-input {
    width: 80px;
  }
  .el-date-editor.el-input__inner,
  .el-form-item .el-form-item__content .el-input {
    width: 220px;
  }
  .el-select .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
}
</style>
